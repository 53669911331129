import React from 'react'
import Layout from '../components/layout-v2';
import '../styles/contact.scss';

const Page = () => (

  <Layout title="Contact us">
    <section className="section-contact">
      <h4>Get in touch</h4>
      <section className="row">
        <div className="contact-card">
          <div className="card-body">
              <h1 className='card-title'>DD Zone, Inc.</h1>
              <div className="card-text">
                <span>290 Whistling Duck Trl,</span><br/>
                <span>Saint Cloud, FL 34771, USA</span><br/>
                <span>E-mail: <a href="mailto:hello@dd.zone">hello@dd.zone</a></span><br/>
                <span><abbr title="Mobile Phone">M: </abbr><a
                    href="tel:+17869299663">(+1) (786) 929-9663</a></span><br/>
                <span><abbr title="Mobile Phone">M: </abbr><a href="tel:+919244099345">(+91) 92440-99345</a></span><br/>
              </div>
              <div className='card-map'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3513.32072815865!2d-81.22899692368048!3d28.28860697585428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd8c72529f0be1%3A0x565cb8fe3f922862!2s290%20Whistling%20Duck%20Trl%2C%20St%20Cloud%2C%20FL%2034771%2C%20USA!5e0!3m2!1sen!2sin!4v1705570385732!5m2!1sen!2sin"
                    frameBorder="0" style={{border: 0}} allowFullScreen></iframe>
              </div>
            </div>
        </div>
        <div className="contact-card">
          <div className="card-body">
              <h1 className='card-title'>DD Zone, Inc.</h1>
              <div className="card-text">
                <span>111 NE 1st St, 8th Floor,</span><br/>
                <span>88114, Miami, FL 33132, USA</span><br/>
                <span>E-mail: <a href="mailto:hello@dd.zone">hello@dd.zone</a></span><br/>
                <span><abbr title="Mobile Phone">M: </abbr><a
                    href="tel:+14102028844">(+1) (410) 202-8844</a></span><br/>
                <span><abbr title="Mobile Phone">M: </abbr><a href="tel:+919244099345">(+91) 92440-99345</a></span><br/>
              </div>
              <div className='card-map'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.8536834519514!2d-80.19293275740321!3d25.77539599515071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b69ebd6f0959%3A0x2d129695a4c85fa6!2s111%20NE%201st%20St%208th%20Floor%2C%20Miami%2C%20FL%2033132%2C%20USA!5e0!3m2!1sen!2sin!4v1672733744735!5m2!1sen!2sin"
                    frameBorder="0" style={{border: 0}} allowFullScreen></iframe>
              </div>
            </div>
        </div>
      </section>
    </section>
  </Layout>
);

export default Page;

